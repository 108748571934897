import React, { Component } from "react";
import $ from "jquery";
import axios from "../../apiConnections/axios";
import { Link } from "react-router-dom";
import SideArticleComponent from "../SharedComponents/SideArticleComponent.js";
import SideArticles from "../SharedComponents/SideArticles.js";
import GridComponent2 from "../SharedComponents/GridComponent2.js";
import { StructuredDataListScript } from "../StructuredData/ItemsListStructuredData.js";
import { Helmet } from "react-helmet";
let flagApi = false;
let updateOnce = false;

class VideoSectionComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      scrollPosition: 0,
      started: false,
    };
  }

  do_load = () => {
    var script = document.createElement("script");
    script.src = "https://cdn.jwplayer.com/players/coaSKWMF-NbhXejOj.js";
    script.defer = true;
    document.body.appendChild(script);
  };

  componentDidMount = () => {
    if (!flagApi) {
      this.loadFeatured();
      flagApi = true;
    }
  };

  componentWillUnmount() {
    flagApi = false;
    updateOnce = false;
    window.removeEventListener("scroll", this.handleScroll);
  }

  renderAd(adId) {
    const AdFunction = `
        <script>sas.cmd.push(function() 
        {sas.call('std', {siteId:391880,pageId:1743691,formatId:${adId},tagId:'bannerAd_sas_${adId}_11111'}, { resetTimestamp: true });});</script>
        `;
    return AdFunction;
  }

  componentDidUpdate = () => {
    if (!updateOnce) {
      window.addEventListener("scroll", this.handleScroll);
      updateOnce = true;
    }
  };

  handleScroll = () => {
    const scrollPosition = window.scrollY;
    this.setState({ scrollPosition });

    if (scrollPosition >= 500 && this.state.started === false) {
      this.do_load();
      this.setState({ started: true, status: "loading" });
    }
  };

  loadFeatured = async (pageNumber) => {
    axios
      .get(`/get-posts-by-tag?tag=latv-featured&itemscount=9`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((res) => {
        this.setState({
          Featured: res.data.data,
          Loading: false,
          listScript: StructuredDataListScript(
            res.data.data,
            "",
            "Featured",
            "Featured"
          ),
        });
        const self = this;
        // setTimeout(function () {
        const content = self.renderAd(119619);
        var div = document.createElement("span");
        div.innerHTML = content;
        $("#bannerAd_sas_119619_11111").html("");
        $("#bannerAd_sas_119619_11111").append(content);
        // }, 100);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { Loading, Featured } = this.state;
    let Bsize = "";
    var self = this;
    return (
      <>
        <Helmet>
          {/* <script type="application/ld+json">
            {JSON.stringify(articleScript)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(listScript)}
          </script> */}
          <script type="application/ld+json">
            {JSON.stringify(this.state.listScript)}
          </script>
        </Helmet>
        <div className="row mt-3 pb-3">
          <div className="col-lg-8 col-md-8 col-sm-12 firstArticle">
            <h2>TRENDING VIDEOS</h2>
            <hr className="latvNetwork" />
            <div className="mt-3">
              <div id="botr_coaSKWMF_NbhXejOj_div"></div>
            </div>
            <div id="bannerAd_sas_119619_11111"></div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12">
            <h2>FEATURED</h2>
            <hr className="latvNetwork" />
            {/* {!Loading ? (
                        Videos.slice(0, 3).map(post => {
                            return (
                                <SideArticles post={post} state= {this.state}/>
                            )
                        })
                    ) : (
                        <>
                            <SideArticleComponent/>
                            <SideArticleComponent/>
                            <SideArticleComponent/>
                        </>
                    ) 
                    } */}
            <GridComponent2
              itemsCount={3}
              state={this.state}
              columns={1}
              pagination={false}
              excerpt={false}
              pageNumber="1"
              Posts={Featured}
              Loading={this.state.Loading}
              gridAds={false}
            />
          </div>
        </div>
      </>
    );
  }
}
export default VideoSectionComponent;
