import React, { Component } from "react";
import $ from "jquery";
import axios from "../../apiConnections/axios";
import { Link } from "react-router-dom";
import noPicture from "../../images/NoPicture.png";
import SpecialsComponent from "../SharedComponents/SpeciaslComponent";
import GridArticleComponent from "../SharedComponents/GridArticleComponent.js";
import GridComponent from "../SharedComponents/GridComponent";
import ImageResize from "../ImageResize/ImageResize.js";
import GridComponent2 from "../SharedComponents/GridComponent2.js";
import { StructuredDataListScript } from "../StructuredData/ItemsListStructuredData.js";
import { Helmet } from "react-helmet";
let flagApi = false;
const itemComponents = [];
const itemsCount = 9;

class FromOurSeriesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      Posts: [],
      activePage: 1,
    };
  }

  componentDidMount = () => {
    if (!flagApi) {
      this.loadPosts(this.state.activePage);
      flagApi = true;
    }
  };

  componentWillUnmount() {
    flagApi = false;
  }

  renderAd(adId) {
    const AdFunction = `
        <script>sas.cmd.push(function() 
        {sas.call('std', {siteId:391880,pageId:1743691,formatId:${adId},tagId:'bannerAd_sas_${adId}_22222'}, { resetTimestamp: true });});</script>
        `;
    return AdFunction;
  }

  loadPosts = async (pageNumber) => {
    this.setState({ Loading: true });
    await axios
      .get(
        `/get-home-page-posts?page_no=${pageNumber}&timestamp=${new Date().getTime()}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        this.setState({
          totalPages: res.data.pagecount,
          activePage: pageNumber,
          Posts: res.data.data,
          Loading: false,
          listScript: StructuredDataListScript(
            res.data.data,
            "",
            "From our series",
            "Home Page"
          ),
        });
        const self = this;
        // setTimeout(function () {
        const content = self.renderAd(119280);
        var div = document.createElement("span");
        div.innerHTML = content;
        $("#bannerAd_sas_119280_22222").html("");
        $("#bannerAd_sas_119280_22222").append(content);
        // }, 100);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    for (let i = 0; i < itemsCount; i++) {
      itemComponents.push(<GridArticleComponent key={i} />);
    }
    let Bsize = "";
    const { Loading, Posts } = this.state;
    return (
      <>
        <Helmet>
          {/* <script type="application/ld+json">
            {JSON.stringify(articleScript)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(listScript)}
          </script> */}
          <script type="application/ld+json">
            {JSON.stringify(this.state.listScript)}
          </script>
        </Helmet>
        <div className="row mt-3 pb-3">
          <div className="col-lg-8 col-md-8 col-sm-12 firstArticle">
            <h2>FROM OUR SERIES</h2>
            <hr className="latvNetwork" />
            {/* <div className="row mt-3 row-cols-1 row-cols-sm-2 row-cols-lg-3 g-lg-3 g-2" data-masonry='{"percentPosition": true }'>
                        {((!Loading) && (Posts!=null) ) ? ( 
                            Posts.slice(0, 9).map(post => {
                                return (
                                    <GridComponent post={post} state={this.state}/>
                                )
                            })
                        ) : (
                            <>
                                <GridArticleComponent/>
                                <GridArticleComponent/>
                                <GridArticleComponent/>
                                <GridArticleComponent/>
                                <GridArticleComponent/>
                                <GridArticleComponent/>
                                <GridArticleComponent/>
                                <GridArticleComponent/>
                                <GridArticleComponent/>
                            </>
                        )}
                        </div> */}
            <GridComponent2
              numPosts={9}
              state={this.state}
              columns={3}
              pagination={false}
              excerpt={true}
              pageNumber="1"
              Posts={Posts}
              Loading={this.state.Loading}
              gridAds={false}
            />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <h2>SPECIALS</h2>
            <hr className="latvNetwork" />
            <SpecialsComponent />
            <div id="bannerAd_sas_119280_22222" className="mt-3"></div>
          </div>
        </div>
      </>
    );
  }
}

export default FromOurSeriesComponent;
