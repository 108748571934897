import React, { Component } from "react";
import $ from "jquery";
import axios from "../../apiConnections/axios";
import { Link } from "react-router-dom";
import noPicture from "../../images/NoPicture.png";
import loadingSVG from "../../images/loadingSVG.svg";
import ImageResize from "../ImageResize/ImageResize.js";
import SideArticleComponent from "../SharedComponents/SideArticleComponent.js";
import SideArticles from "../SharedComponents/SideArticles.js";
import GridComponent2 from "../SharedComponents/GridComponent2.js";
import GridComponentScrollParent from "../SharedComponents/GridComponentScrollParent.js";
import { StructuredDataListScript } from "../StructuredData/ItemsListStructuredData.js";
import { Helmet } from "react-helmet";
let flagApi = false;

class ReadSectionComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      Posts: [],
      activePage: 1,
    };
  }

  componentDidMount = () => {
    if (!flagApi) {
      this.loadPosts(this.state.activePage);
      flagApi = true;
    }
  };

  getScriptId = (srcString) => {
    const scriptId = srcString
      .substring(srcString.indexOf("rs/") + 3, srcString.lastIndexOf(".js"))
      .replaceAll(/-/gi, "_");
    return scriptId;
  };

  getVideoScript = (htmlString) => {
    // Create a temporary div element to parse the HTML string
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;

    // Find the script tag
    const scriptTag = tempDiv.querySelector(
      "script[src^='https://cdn.jwplayer.com/players/']"
    );

    // Extract the src attribute value
    const srcValue = scriptTag ? scriptTag.getAttribute("src") : null;

    return srcValue;
  };

  componentWillUnmount() {
    flagApi = false;
  }

  renderAd(adId) {
    const AdFunction = `
        <script>sas.cmd.push(function() 
        {sas.call('std', {siteId:391880,pageId:1743691,formatId:${adId},tagId:'bannerAd_sas_${adId}_11111'}, { resetTimestamp: true });});</script>
        `;
    return AdFunction;
  }

  adAdds() {
    const self = this;
    // setTimeout(function () {
    const content = self.renderAd(119621);
    var div = document.createElement("span");
    div.innerHTML = content;
    $("#bannerAd_sas_119621_11111").html("");
    $("#bannerAd_sas_119621_11111").append(content);
    // }, 100);
    // setTimeout(function () {
    const content2 = self.renderAd(119280);
    var div2 = document.createElement("span");
    div2.innerHTML = content2;
    $("#bannerAd_sas_119280_11111").html("");
    $("#bannerAd_sas_119280_11111").append(content);
    // }, 100);
  }

  loadPosts = async (pageNumber) => {
    this.setState({ Loading: true });
    await axios
      .get(
        `/get-home-page-posts?page_no=${pageNumber}&timestamp=${new Date().getTime()}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      )
      .then((res) => {
        // const extractedSrc = this.getVideoScript(res.data.data[0].post_content);
        // console.log(res.data.data[0], extractedSrc);
        // const scriptId = this.getScriptId(extractedSrc);
        this.setState({
          totalPages: res.data.pagecount,
          activePage: pageNumber,
          Posts: res.data.data,
          Loading: false,
          listScript: StructuredDataListScript(
            res.data.data,
            "",
            res.data.data[0].post_title,
            res.data.data[0].short_description
          ),
          // trendingVideoId: scriptId,
          // trendingVideoSrc: extractedSrc,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    let Bsize = "";
    const { Loading, Posts } = this.state;
    return (
      <>
        <Helmet>
          {/* <script type="application/ld+json">
            {JSON.stringify(articleScript)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(listScript)}
          </script> */}
          <script type="application/ld+json">
            {JSON.stringify(this.state.listScript)}
          </script>
        </Helmet>
        <div className="row mt-3">
          <div className="col-lg-8 col-md-8 col-sm-12 firstArticle">
            {!Loading ? (
              Posts.slice(0, 1).map((post) => {
                const {
                  ID,
                  author_name,
                  post_category,
                  short_description,
                  post_slug,
                  post_image,
                  post_title,
                  publish_date,
                  imgs,
                  author_login,
                } = post;
                return (
                  <div key={ID}>
                    <Link to={`/${post_slug}/`}>
                      <h1
                        data-bs-toggle="tooltip"
                        title={post_title}
                        dangerouslySetInnerHTML={{
                          __html:
                            post_title !== null && post_title !== ""
                              ? post_title.length > 80
                                ? post_title.split(" ").slice(0, 69).join(" ") +
                                  `...`
                                : post_title
                              : ``,
                        }}
                      ></h1>
                    </Link>
                    {post_image.full_image_url !== null ? (
                      <>
                        <Link to={`/${post_slug}/`}>
                          <ImageResize
                            state={this.state}
                            serie={post}
                            class="img-fluid w-100 card-img-top img-sizing"
                            alter={post.serieName}
                          />
                        </Link>
                      </>
                    ) : (
                      <>
                        <img
                          className="img-fluid img-sizing img-sizing"
                          src="https://dummyimage.com/1920x1080/ff0000/fff.png&text=First+Article+Image"
                          alt=""
                        />
                      </>
                    )}
                    <div className="row mt-3">
                      <div className="main-read-heading clearfix">
                        <span className="main-read-heading-category-span">
                          {post_category[0] &&
                          post_category[0].cat_name !== null
                            ? post_category[0].cat_name
                            : `No Category`}
                        </span>
                        <span className="main-read-heading-author-span">
                          By{" "}
                        </span>
                        <Link to={`/author/${author_login}/`}>
                          <span className="bio-link">{author_name}</span>
                        </Link>
                        <span className="main-read-heading-span">
                          {publish_date}
                        </span>
                      </div>
                    </div>
                    <div className="row mt-3">
                      {short_description !== "" &&
                      short_description !== null ? (
                        <>
                          <div className="main-box-content">
                            <p>
                              {short_description.length > 400
                                ? short_description
                                    .split(" ")
                                    .slice(0, 40)
                                    .join(" ") + ` ...`
                                : short_description}
                            </p>
                          </div>
                          <Link to={`/${post_slug}/`}>
                            <div className="main-box-footer">
                              READ MORE
                              <i className="fa fa-arrow-right d-none d-md-inline"></i>
                            </div>
                          </Link>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div key={1}>
                <h2 className="title-placeholder placeholder"></h2>
                <img
                  className="img-fluid image-placeholder placeholder img-sizing"
                  img-sizing
                  alt=""
                />
                <div className="row mt-3">
                  <div className="main-box-content text-placeholder placeholder"></div>
                </div>
              </div>
            )}
          </div>

          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 max-height-network">
            <h2>LATEST ARTICLES</h2>
            <GridComponentScrollParent
              addClass="side-scroll-network"
              itemsCount={9}
              state={this.state}
              columns={1}
              pagination={false}
              excerpt={false}
              loadType="read"
              pageNumber="1"
              Posts={Posts}
              sidescroll={true}
              Loading={this.state.Loading}
              gridAds2={true}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-5 firstArticle">
            <div id="bannerAd_sas_119621_11111"></div>
          </div>
          {/* <div className="col-lg-4 col-md-4 col-sm-12 mt-5 max-height-network">
          <div id="bannerAd_sas_119280_11111"></div>
        </div> */}
        </div>
      </>
    );
  }
}

export default ReadSectionComponent;
