import React, { Component } from "react";
import axios from "../../apiConnections/axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import { getPageId } from "../Ads/pagesId/smartPageId.js";
import InfiniteScroll from "react-infinite-scroll-component";
import loadingSVG from "../../images/loadingSVG.svg";
import { StructuredDataListScript } from "../StructuredData/ItemsListStructuredData.js";
import { Helmet } from "react-helmet";
let flagApi = false;

class ArticlesSubcategoryComponent extends Component {
  constructor(props) {
    // console.log(props);
    super(props);
    this.state = {
      Loading: true,
      Posts: [],
      matches: window.matchMedia("(min-width: 768px)").matches,
      numColums: "",
      width: "",
      height: "",
      colors: ["pink", "blue", "orange", "yellow", "green", "red"],
      activePage: 1,
      totalPages: 1,
      hasMore: true,
      pageNumber: 1,
      category: "queer",
      SiteId: "391880",
      PageId: "",
      subcategory: "",
    };
  }

  componentDidMount() {
    if (!flagApi) {
      var subcategory = this.props.slug;
      this.setState({
        subcategory: subcategory,
      });
      this.loadPosts(1);
      if ($(window).width() < 768) {
        $(".caro-image").addClass("offset-2");
      }
      const handler = (e) => this.setState({ matches: e.matches });
      this.resizeListener = window.addEventListener("resize", this.columnsCalc);
      this.columnsCalc();
      window
        .matchMedia("(min-width: 768px)")
        .addEventListener("change", handler);
      flagApi = true;
    }
  }

  componentWillUnmount() {
    flagApi = false;
  }

  columnsCalc = () => {
    // console.log("deberia cambiar el display de columnas")
    let screenDivision = Math.floor(window.innerWidth / 300);
    // console.log(screenDivision)
    let columns;
    switch (screenDivision) {
      case 1:
        columns = 12 / 1;
        break;
      case 2:
        columns = 12 / 2;
        break;
      case 3:
        columns = 12 / 3;
        break;
      case 4:
        columns = 12 / 4;
        break;
      case 5:
        columns = 12 / 4;
        break;
      case 6:
        columns = 12 / 6;
        break;
      case 7:
        columns = 12 / 6;
        break;
      case 8:
        columns = 12 / 6;
        break;
      case 9:
        columns = 12 / 6;
        break;
      case 10:
        columns = 12 / 6;
        break;
      case 11:
        columns = 12 / 6;
        break;
      case 12:
        columns = 12 / 12;
        break;
      case 13:
        columns = 12 / 12;
        break;
      default:
        break;
    }
    let sizeWH = window.innerWidth / screenDivision - 38;
    this.setState({ numColums: columns, width: sizeWH, height: sizeWH });
  };

  loadPosts = async (pageNumber) => {
    // console.log(this.state.subcategory);
    if (this.state.totalPages < this.state.pageNumber) {
      this.setState({ hasMore: false });
      return;
    }

    // this.setState({ Loading: true });
    const pageId = getPageId("queer");
    var category = this.state.category;
    var subcategory = this.props.slug;
    // console.log(category);
    // console.log(subcategory);
    var customPageNum = this.state.pageNumber;
    var apipath = `/get-post-by-category?page_no=${customPageNum}&category=${category}-${subcategory}&timestamp=${new Date().getTime()}`;
    // var apipath = `/get-post-by-category?page_no=${customPageNum}&category=${category}&timestamp=${new Date().getTime()}`;
    await axios
      .get(`${apipath}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then((res) => {
        // console.log("our latest articles", res);
        this.setState({
          totalPages: res.data.pagecount,
          activePage: customPageNum,
          Posts: this.state.Posts.concat(res.data.data),
          Loading: false,
          pageNumber: customPageNum + 1,
          PageId: pageId,
          listScript: StructuredDataListScript(
            res.data.data,
            `queer/${subcategory}`,
            `Queer ${subcategory}`,
            "Queer subcategory latest articles"
          ),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  appendAdsToPost(post) {
    // console.log(post)
    if (!post.adAppended) {
      let randDivIdBot = parseInt(Math.random() * 1000000000);
      post.randDivIdBot = randDivIdBot;
      // console.log("le va a hacer append a el post",post)
      const pageId = this.state.PageId;
      const siteId = this.state.SiteId;
      const bot_content_formats = ["119280"];
      setTimeout(() => {
        bot_content_formats.forEach((format) => {
          this.appendAd(post.randDivIdBot, format, pageId, siteId, "bot");
        });
      }, 100);
      post.adAppended = true;
    }
  }

  //Type can be top/bot/side
  appendAd(divId, format, pageId, siteId, type) {
    var rndId = parseInt(Math.random() * 1000000);
    rndId = `sas_${format}_${rndId}`;
    var art = document.createElement("div");
    $(art).addClass(`${type}-adverties`);

    var smartDiv = document.createElement("div");
    smartDiv.id = "bannerAd_" + rndId;

    var option = "{ resetTimestamp: true }";
    var smartTag = document.createElement("script");
    var code =
      `sas.cmd.push(function() {sas.call('std', {siteId:${siteId},pageId:${pageId},formatId:${format},tagId:'bannerAd_` +
      rndId +
      "'}, " +
      option +
      ");});";
    $(smartTag).text(code);
    art.appendChild(smartDiv);
    art.append(smartTag);
    $("#" + divId).append(art);
    console.log($("#" + divId).get());
  }

  loadAd(index) {
    var rndId = parseInt(Math.random() * 1000000);

    var art = document.createElement("div");
    $(art).addClass("article");

    var artBanner = document.createElement("div");
    artBanner.id = "bannerAd_" + rndId;
    $(`#articles_${index}`).append(artBanner);

    var option = "{ resetTimestamp: true }";
    var smartTags = document.createElement("script");
    var code =
      "sas.cmd.push(function() {sas.call('std', {siteId:391880,pageId:1743691,formatId:119280,tagId:'bannerAd_" +
      rndId +
      "'}, " +
      option +
      ");});";
    $(smartTags).text(code);
    $(`#articles_${index}`).append(smartTags);
  }

  renderTopAd(adId) {
    const topAdFunction = `
    <script>sas.cmd.push(function() 
    {sas.call('std', {siteId:391880,pageId:1701583,formatId:${adId},tagId:'bannerAd_sas_${adId}_11111'}, { resetTimestamp: true });});</script>
    `;
    return topAdFunction;
  }

  render() {
    const {
      Loading,
      Posts,
      Pages,
      numColums,
      width,
      height,
      colors,
      listScript,
    } = this.state;
    let contador = 0;
    return (
      <>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(listScript)}
          </script>
        </Helmet>
        <section
          id="gallery-section"
          className="gallery-section"
          style={{
            ...(!this.state.matches
              ? { marginBottom: `0` }
              : { marginBottom: `45px` }),
          }}
        >
          <div className="container queer">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                <h2 className="sub-title" style={{ padding: "30px 0 30px 0" }}>
                  OUR LATEST ARTICLES
                </h2>
                {/* falta un margen arriba y el color negro en la fuente */}
              </div>
            </div>
            {!Loading ? (
              <InfiniteScroll
                dataLength={this.state.Posts.length}
                next={this.loadPosts}
                hasMore={this.state.hasMore}
                className="row"
                loader={
                  <div
                    className="col-md-12"
                    style={{ textAlign: "center", padding: "20px" }}
                  >
                    <img
                      placeholder={loadingSVG}
                      src={loadingSVG}
                      className="img-fluid img-responsive  bottom-img-spinner img-sizing"
                      alt="alt"
                    />
                  </div>
                }
                endMessage={
                  <div className="col-md-12">
                    <p style={{ textAlign: "center" }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  </div>
                }
              >
                {this.state.Posts.map((post, index) => {
                  // if (index !== 0 && (index + 2) % 4 === 0) {
                  //   this.appendAdsToPost(post);
                  // }
                  const {
                    ID,
                    post_image,
                    post_title,
                    post_slug,
                    post_type,
                    imgs,
                    short_description,
                  } = post;
                  // post.randDivIdBot = parseInt(Math.random() * 1000000000);
                  if (index !== 0 && (index + 2) % 4 === 0) {
                    this.appendAdsToPost(post);
                  }

                  if (post_type === "post" && index > 2) {
                    return (
                      <>
                        <div className={"col-" + numColums} key={ID}>
                          <div className="square">
                            <div>
                              <Link to={`/queer/${post_slug}/`}>
                                <div
                                  className={
                                    "articles-image img-border-" + colors[1]
                                  }
                                >
                                  <img
                                    sizes="(max-width: 480px) 100vw, (max-width: 768px) 50vw, 33vw"
                                    srcSet={imgs
                                      .toReversed()
                                      .map((image, index) => {
                                        if (image[1] === undefined) {
                                          return [];
                                        }
                                        const Srcset = [];
                                        const Zize = image[1].toString();
                                        const link = image[0].toString();
                                        Srcset.push(link + " " + Zize + "w");
                                        return Srcset;
                                      })}
                                    src={post_image.medium_image_url}
                                    // cache
                                    className="img-fluid img-responsive w-100 img-sizing"
                                    alt="alt"
                                    width="1000px"
                                    height="563px"
                                  />
                                </div>
                                <div className="bottomRead-Content">
                                  <div className="bottom-content black-text">
                                    <h5
                                      dangerouslySetInnerHTML={{
                                        __html: post_title,
                                      }}
                                    ></h5>
                                  </div>
                                  <div className="bottom-content black-text line-clamp">
                                    <p className="queer">{short_description}</p>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                        {index !== 0 && (index + 2) % 4 === 0 ? (
                          <div
                            className={"col-" + numColums}
                            style={{ marginBottom: "30px" }}
                          >
                            <div>
                              <div
                                className={"square categoory "}
                                sizes="(max-width: 480px) 100vw, (max-width: 768px) 50vw, 33vw"
                                style={{
                                  border: "1px solid #71BF45",
                                }}
                              >
                                <div className="text">
                                  <h3></h3>
                                </div>
                              </div>
                              <div
                                className="bot-adverties"
                                style={{
                                  backgroundColor: "rgb(237, 56, 151, 0.1)",
                                  height: "250px",
                                  border: "1px solid #ED3897",
                                }}
                              >
                                <p style={{ visibility: "hidden" }}>
                                  sas ads bot
                                </p>
                                <div id={post.randDivIdBot}> </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    );
                  } else if (post_type === "watch" && index > 2) {
                    return (
                      <>
                        <div className={"col-" + numColums} key={ID}>
                          <div className="square">
                            <div>
                              <a href={`../watch/${post_slug}/`}>
                                <div className="">
                                  <div className="side-video-thumbnail">
                                    <img
                                      sizes="(max-width: 480px) 100vw, (max-width: 768px) 50vw, 33vw"
                                      srcSet={imgs
                                        .toReversed()
                                        .map((image, index) => {
                                          if (image[1] === undefined) {
                                            return [];
                                          }
                                          const Srcset = [];
                                          const Zize = image[1].toString();
                                          const link = image[0].toString();
                                          Srcset.push(link + " " + Zize + "w");
                                          return Srcset;
                                        })}
                                      // src={post_image.medium_image_url}
                                      src={post_image.medium_image_url}
                                      loading="lazy"
                                      cache
                                      className="img-fluid w-100 h-auto img-sizing"
                                      alt={post_image.image_alt}
                                      width="1000px"
                                      height="563px"
                                    />
                                    <div></div>
                                  </div>
                                  <div className="bottomRead-Content">
                                    <div className="bottom-content black-text">
                                      <h5
                                        dangerouslySetInnerHTML={{
                                          __html: post_title,
                                        }}
                                      ></h5>
                                    </div>
                                  </div>
                                  <div className="bottom-content black-text line-clamp">
                                    <p className="queer">{short_description}</p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        {index !== 0 && (index + 2) % 4 === 0 ? (
                          <div className={"col-" + numColums}>
                            <div className="bot-adverties">
                              <p style={{ visibility: "hidden" }}>
                                sas ads bot
                              </p>
                              <div id={post.randDivIdBot}> </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    );
                  }
                })}
              </InfiniteScroll>
            ) : (
              <></>
            )}
          </div>
          <div className="flex-center">
            {this.state.matches ? (
              <div id="6644">
                <div class="top-adverties">
                  <div
                    id="bannerAd_sas_119619_11111"
                    className="Ad119619"
                  ></div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </section>
      </>
    );
  }
}

export default ArticlesSubcategoryComponent;
